import { useState } from 'react';

import { CurrentVersionCard } from './CurrentVersionCard/CurrentVersionCard';
import { VersionModal } from './VersionModal';
import styles from './VersionSupport.module.css';

export function VersionSupport() {
	const [open, setOpen] = useState(false);

	return (
		<div className={styles.versionBanner}>
			<VersionModal open={open} onClose={() => setOpen(false)} />

			<CurrentVersionCard onClick={() => setOpen(true)} />
		</div>
	);
}
