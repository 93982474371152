import { ChevronUpMinor, ChevronDownMinor } from '@sixriver/lighthouse-icons';
import { Card, Collapsible, Heading, Icon, Stack } from '@sixriver/lighthouse-web-community';

import styles from './Shuttle.module.css';

interface Props {
	title: string;
	onHeaderClick(): void;
	iconStyle?: React.CSSProperties;
	open: boolean;
	icon: string;
	color?: string;
}

export function CollapsibleShuttlePointCard({
	children,
	title,
	onHeaderClick,
	open,
	icon,
	color,
}: React.PropsWithChildren<Props>) {
	return (
		<div className={styles.cardOuter}>
			<Card>
				<header
					className={styles.collapsibleCardHeader}
					role="button"
					onClick={onHeaderClick}
					aria-expanded={open}
				>
					<Stack distribution="equalSpacing">
						<svg width="24" height="24" style={{ color }}>
							<use xlinkHref={icon}></use>
						</svg>
						<Heading>{title}</Heading>
						<Icon source={open ? ChevronUpMinor : ChevronDownMinor} />
					</Stack>
				</header>
				<Collapsible
					open={open}
					id={title.replace(' ', '-')}
					transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
					expandOnPrint
				>
					{children}
				</Collapsible>
			</Card>
		</div>
	);
}
