import {
	Card,
	ContextualSaveBar,
	Form,
	Layout,
	Stack,
	TextField,
} from '@sixriver/lighthouse-web-community';
import { useCallback, useMemo } from 'react';

import { DeviceEditFormData, DeviceEditFormInfo } from './Device.utils';
import { DeviceBadge } from './DeviceBadge';
import { DeviceBattery } from './DeviceBattery';
import RelatedJobs from './RelatedJobs';
import RelatedOrders from './RelatedOrders';
import { DeviceType } from '../../api';
import { CardDetails } from '../../components/CardDetails';
import { DateTime } from '../../components/DateTime';
import { BasicFormProps, useBasicForm } from '../../hooks/useBasicForm';
import { useIsWorkAreasEnabled } from '../../hooks/useConfig';
import { useLocalization } from '../../hooks/useLocalization';

interface DeviceEditFormAdditionalProps {
	info: DeviceEditFormInfo;
}

type Props = BasicFormProps<DeviceEditFormData> & DeviceEditFormAdditionalProps;

export function DeviceEditForm<T extends Props>({
	data,
	info,
	errors: formErrors,
	onSubmit,
}: T): JSX.Element {
	const isWorkAreasEnabled = useIsWorkAreasEnabled();

	const { messages } = useLocalization();

	// Form
	const defaultValues: DeviceEditFormData = useMemo(
		() => ({
			name: data.name,
		}),
		[data],
	);

	const { editForm, discardForm, input, dirty, validations } = useBasicForm<DeviceEditFormData>(
		defaultValues,
		formErrors,
	);

	const submitForm = useCallback(() => {
		onSubmit(input);
	}, [input, onSubmit]);

	const device = info.device;

	return (
		<Form onSubmit={submitForm} noValidate preventDefault>
			{/* Save Bar */}
			{dirty ? (
				<ContextualSaveBar
					alignContentFlush={false}
					message={messages.unsavedChanges}
					saveAction={{
						content: messages.save,
						disabled: false,
						loading: false,
						onAction: submitForm,
					}}
					discardAction={{
						content: messages.discard,
						onAction: discardForm,
					}}
				/>
			) : null}

			<Layout>
				<Layout.Section>
					{/* Device Profile */}
					<Layout.AnnotatedSection
						title={messages.formDeviceEdit.section.deviceProfile.title}
						description={messages.formDeviceEdit.section.deviceProfile.description}
					>
						<Card sectioned>
							<Card.Section fullWidth flush>
								<Stack vertical>
									<TextField
										autoComplete="off"
										name="name"
										label={messages.name}
										error={validations?.name}
										value={input.name}
										disabled={info.model !== DeviceType.Chuck}
										onChange={(name) => editForm({ name })}
									/>
									<TextField
										autoComplete="off"
										name="model"
										label={messages.model}
										value={info.model}
										disabled
									/>
									<TextField
										autoComplete="off"
										name="network"
										label={messages.network}
										value={info.network}
										disabled
									/>
								</Stack>
							</Card.Section>
						</Card>
					</Layout.AnnotatedSection>

					{/* Device Info */}
					<Layout.AnnotatedSection
						title={messages.formDeviceEdit.section.deviceInfo.title}
						description={messages.formDeviceEdit.section.deviceInfo.description}
					>
						<Card sectioned>
							<Card.Section fullWidth flush>
								<CardDetails
									primary={[
										{
											content: device ? <DeviceBattery device={device} /> : <></>,
											label: messages.batteryLevel,
										},
										{
											content: <DateTime date={info.lastCalibratedAt} />,
											label: messages.lastCalibrated,
										},
									]}
								/>
							</Card.Section>
						</Card>
					</Layout.AnnotatedSection>

					{/* Device Activity */}
					<Layout.AnnotatedSection
						title={messages.formDeviceEdit.section.deviceActivity.title}
						description={messages.formDeviceEdit.section.deviceActivity.description}
					>
						<Card sectioned>
							<Card.Section fullWidth flush>
								<CardDetails
									primary={[
										{
											content: device ? <DeviceBadge device={device} /> : <></>,
											label: messages.status,
										},
										{
											content: device ? <DateTime date={device?.updatedAt} /> : <></>,
											label: messages.lastActive,
										},
										...(isWorkAreasEnabled
											? [
													{
														content: device?.currentPose?.mapChunk.displayName,
														label: messages.workArea,
													},
											  ]
											: []),

										...(info.model === DeviceType.TerminalOnWheels ||
										info.model === DeviceType.Handheld
											? []
											: [
													{
														content: device ? <RelatedOrders device={device} /> : <></>,
														label: messages.orders,
													},
													{
														content: device ? <RelatedJobs device={device} /> : <></>,
														label: messages.jobs,
													},
											  ]),
									]}
								/>
							</Card.Section>
						</Card>
					</Layout.AnnotatedSection>
				</Layout.Section>
			</Layout>
		</Form>
	);
}
