import { ShuttlePoint } from '@sixriver/task-coordinator-oas';

import { CollapsibleShuttlePointCard } from './CollapsibleShuttlePointCard';
import { ShuttlePointOptions } from './ShuttlePointOptions';
import pointImg from '../../../images/point.svg';

interface Props {
	shuttlePoint: ShuttlePoint;
	selected: boolean;
	onHeaderClick(): void;
	onDelete(): void;
	onChange: (shuttlePoint: ShuttlePoint) => void;
	workflowEligible: boolean;
}

/**
 * This component displays shuttle point details
 */
export function ShuttlePointCard({
	shuttlePoint,
	selected,
	onHeaderClick,
	onDelete,
	onChange,
	workflowEligible,
}: Props) {
	return (
		<CollapsibleShuttlePointCard
			title={shuttlePoint.name}
			onHeaderClick={onHeaderClick}
			open={selected}
			icon={`${pointImg}#point`}
			color={shuttlePoint.color}
		>
			<ShuttlePointOptions
				onChange={onChange}
				shuttlePoint={shuttlePoint}
				onDelete={onDelete}
				workflowEligible={workflowEligible}
			/>
		</CollapsibleShuttlePointCard>
	);
}
