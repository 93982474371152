import { Badge } from '@sixriver/lighthouse-web-community';

import { useLocalization } from '../../../../hooks/useLocalization';
import { endOfLifeDate, warningDate } from '../lts-dates';

export function SupportBadge() {
	const now = new Date();
	const { messages } = useLocalization();

	if (now > endOfLifeDate) {
		return <Badge status="critical">{messages.supportBadge.unsupported}</Badge>;
	}

	if (now > warningDate) {
		return <Badge status="warning">{messages.supportBadge.warning}</Badge>;
	}

	return (
		<Badge size="small" status="success">
			{messages.supportBadge.supported}
		</Badge>
	);
}
