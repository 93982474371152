import { DateInterval } from '@hyper-fetch/core';
import { ShuttleRoute } from '@sixriver/task-coordinator-oas';

import { client } from './client';

export const shuttlesByMapChunk = client.createRequest<
	ShuttleRoute[],
	{ where?: Record<any, any>; limit?: number; fields?: Record<string, boolean> }
>()({
	cacheTime: DateInterval.minute,
	endpoint: `/cfs/task-coordinator/v1/Shuttles/find`,
	method: 'GET',
});

export const addOrUpdateShuttle = client.createRequest<ShuttleRoute, ShuttleRoute>()({
	endpoint: `/cfs/task-coordinator/v1/Shuttles/addOrUpdate`,
	method: 'POST',
});
