import { TextStyle } from '@sixriver/lighthouse-web-community';

import { CycleType } from './Shuttle.type';
import { useLocalization } from '../../../hooks/useLocalization';

interface CycleTypeExplanationProps {
	type: CycleType;
}

export function CycleTypeExplanation({ type }: CycleTypeExplanationProps) {
	const { messages } = useLocalization();
	switch (type) {
		case 'loop':
			return <TextStyle>{messages.shuttleRouteCycleType.loop}</TextStyle>;
		case 'oneShot':
			return <TextStyle>{messages.shuttleRouteCycleType.oneShot}</TextStyle>;
		case 'pingPong':
			return <TextStyle>{messages.shuttleRouteCycleType.pingPong}</TextStyle>;
		default:
			return null;
	}
}
