import { PlusMinor } from '@sixriver/lighthouse-icons';
import { Button, Stack } from '@sixriver/lighthouse-web-community';
import { ShuttleRoute } from '@sixriver/task-coordinator-oas';

import { ShuttleRouteListCard } from './ShuttleRouteListCard';
import { useLocalization } from '../../../hooks/useLocalization';
import { MapSelection } from '../MapSelection';
import { SidePane } from '../SidePane';
import sidePaneStyles from '../SidePane.module.css';

interface ShuttleRoutesListProps {
	shuttleRoutes: ShuttleRoute[];
	onSelectShuttle(id: string): void;
	onDismiss(): void;
	onNewShuttle(): void;
	mapSelection?: MapSelection;
}

export function ShuttleRoutesList({
	shuttleRoutes,
	onDismiss,
	onSelectShuttle,
	onNewShuttle,
}: ShuttleRoutesListProps) {
	const { messages, translate } = useLocalization();

	return (
		<SidePane
			title={translate(messages.shuttleRoutes.title, { number: shuttleRoutes.length })}
			onDismiss={onDismiss}
			actions={
				<Stack alignment="center" distribution="center">
					<Button icon={PlusMinor} primary onClick={onNewShuttle}>
						{messages.shuttleRouteNew}
					</Button>
				</Stack>
			}
		>
			<ul className={sidePaneStyles.list}>
				{shuttleRoutes.map((shuttle) => {
					return (
						<ShuttleRouteListCard
							key={shuttle.id}
							shuttle={shuttle}
							onClick={() => onSelectShuttle(shuttle.id)}
						/>
					);
				})}
			</ul>
		</SidePane>
	);
}
