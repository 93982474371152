import { TextStyle } from '@sixriver/lighthouse-web-community';

import { useWarehouseApi } from '../../../../hooks/useWarehouseApi';

export function VersionNumber() {
	const usingWarehouseApi = useWarehouseApi();

	return (
		<TextStyle variation="subdued">
			{APP_VERSION} ({usingWarehouseApi ? 'W' : 'F'})
		</TextStyle>
	);
}
