import { useMemo } from 'react';

import { useSingleFetch } from './useSingleFetch';
import {
	GlobalErrorType,
	AvailableMfp,
	getAvailableMfps,
	getAvailableMfp,
	MfpConfigExtended,
	DeviceType,
	DeviceState,
} from '../api';

export function useAvailableMfps(): {
	data: AvailableMfp[];
	error?: GlobalErrorType | null;
	fetching: boolean;
} {
	const { loading, data, error } = useSingleFetch(getAvailableMfps);
	return { data: data ?? [], error, fetching: loading };
}

export function useAvailableMfp(id: string): {
	data: AvailableMfp | null;
	error: GlobalErrorType | null | undefined;
	fetching: boolean;
} {
	const req = useMemo(() => getAvailableMfp.setParams({ id }), [id]);
	const { loading, data, error } = useSingleFetch(req);
	return { data, error, fetching: loading };
}

export function getDeviceType(config: MfpConfigExtended): DeviceType {
	return determineDeviceType(config.config?.deviceClass);
}

export function determineDeviceType(deviceClass: string | undefined): DeviceType {
	switch (deviceClass) {
		case 'packoutTerminal':
			return DeviceType.PackoutTerminal;
		case 'sortationKiosk':
			return DeviceType.SortationKiosk;
		case 'terminalOnWheels':
			return DeviceType.TerminalOnWheels;
		case 'handheld':
			return DeviceType.Handheld;
		case 'chuck':
			return DeviceType.Chuck;
		case undefined:
			return DeviceType.Chuck;
		default:
			return DeviceType.Unknown;
	}
}

export function getDeviceState(device: AvailableMfp): DeviceState {
	switch (device.state) {
		case 'ALLOCATED':
		case 'ALLOCATED_REMOTE':
			return DeviceState.InUse;
		case 'AVAILABLE':
		case 'AVAILABLE_REMOTE':
			return DeviceState.NotInUse;
		case 'OFFLINE':
			return DeviceState.Disconnected;
	}
}

export function isVirtualDeviceName(name: string, siteName: string): boolean {
	return virtualDeviceNamePatterns(siteName).some((regExp) => regExp.test(name));
}

function virtualDeviceNamePatterns(siteName: string): RegExp[] {
	return [
		new RegExp(`mfp-${siteName}-\\d+`),
		new RegExp(`mfp-${siteName}-49-\\d+`),
		new RegExp(`handheld-${siteName}-\\d+`),
		new RegExp(`orin-${siteName}-\\d+`),
		new RegExp(`mfp-debug`),
	];
}
