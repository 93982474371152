import { useCallback, useEffect, useState } from 'react';

export interface BasicFormProps<T> {
	mode: 'add' | 'edit';
	data: T;
	errors?: Partial<Record<keyof T, string>>;
	onSubmit: (data: T) => void;
}

// NOTE: We need another form hook because the current useForm is tightly coupled to GraphQL.
export function useBasicForm<T>(data: T, errors?: Partial<Record<keyof T, string>>) {
	// deep clone the source data so it is never mutated and we can revert back to it
	const [input, setInput] = useState<T>({ ...data });
	const [dirty, setDirty] = useState(false);
	const [validations, setValidations] = useState(errors);

	useEffect(() => {
		setValidations(errors);
	}, [setValidations, errors]);

	const editForm = useCallback(
		(partialInput: Partial<T>) => {
			// dirty the form
			setDirty(true);

			// update form input
			setInput({ ...input, ...partialInput });

			const updatedKeys = Object.keys(partialInput);
			if (updatedKeys.length > 0 && validations) {
				// discard form validations for changed fields
				const newValidations = { ...validations };
				updatedKeys.forEach((key) => {
					if (Object.hasOwn(validations, key)) {
						delete newValidations[key as keyof T];
					}
				});
				setValidations(newValidations);
			}
		},
		[input, validations],
	);

	const discardForm = useCallback(() => {
		setValidations({});
		setInput(data);
		setDirty(false);
	}, [data]);

	return { dirty, discardForm, editForm, input, validations };
}
