import { useMemo } from 'react';

import { AvailableMfp, GlobalErrorType, MfpConfigExtended } from '../../api';
import { useAvailableMfps } from '../useAvailableMfps';
import { useMfpConfigs } from '../useConfig';

export type DeviceAndConfig = {
	device: AvailableMfp;
	mfpConfig?: MfpConfigExtended;
};

export type DeviceWithConfig = {
	device: AvailableMfp;
	mfpConfig: MfpConfigExtended;
};

export type UseDevicesWithConfigsReturnType = {
	fetching: boolean;
	data: DeviceWithConfig[] | null;
	error: GlobalErrorType | null;
};

export function useDevicesWithConfigs(): UseDevicesWithConfigsReturnType {
	const {
		fetching: availableMfpsFetching,
		data: availableMfps,
		error: availableMfpsError,
	} = useAvailableMfps();
	const {
		fetching: mfpConfigsFetching,
		data: mfpConfigs,
		error: mfpConfigsError,
	} = useMfpConfigs();

	const fetching = availableMfpsFetching || mfpConfigsFetching;

	const devicesAndConfigs = useMemo(() => {
		if (fetching || availableMfpsError || mfpConfigsError) {
			return null;
		}

		const mfpConfigsMap = mfpConfigs.reduce<Map<string, MfpConfigExtended>>((map, mfpConfig) => {
			map.set(mfpConfig.name, mfpConfig);
			return map;
		}, new Map());

		// NOTE: Currently the id of the AvailableMfp is (matches) the name of the MfpConfig
		const devicesAndConfigs = availableMfps.map<DeviceAndConfig>((device) => {
			return {
				device,
				mfpConfig: mfpConfigsMap.get(device.id),
			};
		});

		// Filter out available MFPs for which cannot find matching MFP Config
		const result = devicesAndConfigs.filter(({ mfpConfig }) => !!mfpConfig) as DeviceWithConfig[];

		return result;
	}, [fetching, availableMfpsError, mfpConfigsError, mfpConfigs, availableMfps]);

	return {
		data: devicesAndConfigs,
		error: availableMfpsError || mfpConfigsError || null,
		fetching,
	};
}
