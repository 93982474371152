import { OrderV2, QueryOrderV2Args } from '@sixriver/fulfillment-api-schema';
import { MarkFulfilledMinor } from '@sixriver/lighthouse-icons';
import {
	Frame,
	Layout,
	Loading,
	MenuActionDescriptor,
	Page,
} from '@sixriver/lighthouse-web-community';
import { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { Details } from './Details';
import { ORDER_QUERY } from './Order.graphql';
import { AutoRefresh } from '../../components/AutoRefresh';
import { CancelOrderModal } from '../../components/CancelOrderModal';
import { Error } from '../../components/Error';
import { Lines } from '../../components/Lines';
import { LinesTableColumn } from '../../components/Lines/LinesTable';
import { useCancelOrder } from '../../hooks/useCancelOrder';
import { useIsWorkAreasEnabled } from '../../hooks/useConfig';
import { useApplyManualPickHealPlan } from '../../hooks/useHealPlan';
import { useLocalization } from '../../hooks/useLocalization';
import { usePolling } from '../../hooks/usePolling';
import { usePollingQuery } from '../../hooks/usePollingQuery';

export interface RouteMatchParams {
	exceptionId: string;
}

export function Exception() {
	const {
		params: { exceptionId },
	} = useRouteMatch<RouteMatchParams>();
	const { pollingEnabled, togglePolling, queryPollInterval } = usePolling();
	const { messages } = useLocalization();
	const [{ fetching, error, data }] = usePollingQuery<{ job: OrderV2 }, QueryOrderV2Args>({
		context: useMemo(
			() => ({
				useWarehouseApi: true,
			}),
			[],
		),
		pollInterval: queryPollInterval,
		query: ORDER_QUERY,
		variables: {
			id: exceptionId,
		},
	});
	const { job: order } = data || {};
	const { isCancelModalOpen, openCancelModal, closeCancelModal, cancelOrder, cancelOrderLoading } =
		useCancelOrder({ orderId: exceptionId });
	const isWorkAreaEnabled = useIsWorkAreasEnabled();

	const actions: MenuActionDescriptor[] = [
		{
			content: messages.cancel,
			disabled: order?.isCancelable === false,
			onAction: openCancelModal,
		},
	];

	const { fetching: applyManualHealPlanFetching, applyManualHealPlan } =
		useApplyManualPickHealPlan();
	if ((order as any)?.isHealable) {
		actions.push({
			content: messages.manuallyResolved,
			icon: MarkFulfilledMinor,
			loading: applyManualHealPlanFetching,
			onAction: () => {
				if (order) {
					applyManualHealPlan(order.id);
				}
			},
		});
	}

	if (fetching) {
		return (
			<Frame>
				<Loading />
			</Frame>
		);
	}

	if (error) {
		return <Error graphQLError={error} />;
	}

	if (!order) {
		return <Error heading={messages.orderNotFound} />;
	}

	return (
		<Page title={exceptionId} secondaryActions={actions}>
			<Layout>
				<Layout.Section>
					<AutoRefresh
						pollingEnabled={pollingEnabled}
						togglePolling={togglePolling}
						discriminatorData={order}
					/>
				</Layout.Section>
				<Layout.Section>
					<Details isWorkAreaEnabled={isWorkAreaEnabled} order={order} />
				</Layout.Section>
				<Layout.Section>
					<Lines
						job={order}
						columns={[
							LinesTableColumn.id,
							LinesTableColumn.name,
							LinesTableColumn.pickingUnits,
							LinesTableColumn.exceptions,
							LinesTableColumn.reason,
							LinesTableColumn.location,
						]}
					/>
				</Layout.Section>
			</Layout>
			<CancelOrderModal
				isOpen={isCancelModalOpen}
				onClose={closeCancelModal}
				onConfirm={cancelOrder}
				loading={cancelOrderLoading}
			/>
		</Page>
	);
}
