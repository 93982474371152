import { useAuth } from '@sixriver/react-support';
import { Route, RouteProps, useHistory } from 'react-router-dom';

import { redirectUserAfterLogin } from '../../pages/Auth/redirect-user-after-login';

export function LoginRoute({ children, ...rest }: RouteProps) {
	const { isAuthenticated } = useAuth();
	const history = useHistory();

	if (isAuthenticated()) {
		redirectUserAfterLogin(history);
	}

	return <Route {...rest} render={() => children} />;
}
