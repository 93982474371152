import { Stack, TextStyle } from '@sixriver/lighthouse-web-community';

import CHARGING_IMG from '../../images/charging.svg';
import DOCKING_IMG from '../../images/docking.svg';
import DWELLING_IDLE_IMG from '../../images/dwellingIdle.svg';
import DWELLING_PAUSED_IMG from '../../images/dwellingPaused.svg';
import IDLE_IMG from '../../images/idle.svg';
import LOADING_IMG from '../../images/loading.svg';
import LOW_BATTERY_IMG from '../../images/lowBattery.svg';
import OFFLINE_IMG from '../../images/offline.svg';
import PAUSED_IMG from '../../images/paused.svg';
import PRIORITY_IMG from '../../images/priority.svg';
import TRAVELING_IMG from '../../images/traveling.svg';
import UPGRADING_IMG from '../../images/upgrading.svg';

interface Props {
	chuckId: string;
	motionState: string;
	isDwellingIdle: boolean;
	isDwellingPaused: boolean;
	isFaulty: boolean;
	isLowBattery: boolean;
	isUpgrading: boolean;
	isOffline: boolean;
	isPriority: boolean;
}

export function ChuckTitle({
	chuckId,
	motionState,
	isDwellingIdle,
	isDwellingPaused,
	isFaulty,
	isLowBattery,
	isUpgrading,
	isOffline,
	isPriority,
}: Props) {
	let src;

	if (isOffline) {
		src = OFFLINE_IMG;
	} else if (isUpgrading) {
		src = UPGRADING_IMG;
	} else if (isPriority) {
		src = PRIORITY_IMG;
	} else if (motionState === 'charging') {
		src = CHARGING_IMG;
	} else if (isDwellingIdle) {
		src = DWELLING_IDLE_IMG;
	} else if (isDwellingPaused) {
		src = DWELLING_PAUSED_IMG;
	} else if (isLowBattery) {
		src = LOW_BATTERY_IMG;
	} else if (motionState === 'docking') {
		src = DOCKING_IMG;
	} else if (motionState === 'loading') {
		src = LOADING_IMG;
	} else if (motionState === 'traveling') {
		src = TRAVELING_IMG;
	} else if (motionState === 'paused') {
		src = PAUSED_IMG;
	} else {
		src = IDLE_IMG;
	}

	return (
		<Stack spacing="tight">
			<img src={src} width="18" height="18" data-testid="icon" alt="" />
			<TextStyle variation={isFaulty ? 'negative' : 'strong'}>{chuckId}</TextStyle>
		</Stack>
	);
}
