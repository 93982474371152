import { ExtractResponseType, ExtractErrorType, RequestInstance } from '@hyper-fetch/core';
import { useFetch as useHyperFetch, UseFetchOptionsType } from '@hyper-fetch/react';
import { useEffect, useState } from 'react';

export interface UseFetchReturnType<RequestType extends RequestInstance> {
	loading: boolean;
	data: ExtractResponseType<RequestType> | null;
	error: ExtractErrorType<RequestType> | null;
}

// useSingleFetch fixes the problems with the result transitions of useFetch for
// requests that do not change or they change from disabled fetching to having to make
// a single fetch request.
//
// Currently useFetch has a bug where it goes from { loading: true, data: null, error: null }
// to { loading: false, data: null, error: null } and then { loading: false, data: object, error: null }
// which breaks our UI logic.
export function useSingleFetch<RequestType extends RequestInstance>(
	request: RequestType,
	options?: UseFetchOptionsType<RequestType>,
): UseFetchReturnType<RequestType> {
	const [loading, setLoading] = useState(!options?.disabled);
	const [data, setData] = useState<ExtractResponseType<RequestType> | null>(null);
	const [error, setError] = useState<ExtractErrorType<RequestType> | null>(null);
	let dependencies = options?.dependencies;
	if (dependencies) {
		dependencies = [...dependencies, loading, data, error];
	}
	let disabled = options?.disabled;
	// NOTE: We disable the fetch if we already have a result for the request
	if (disabled) {
		disabled ||= !!(data || error);
	}

	useEffect(() => {
		if (!data && !error && !loading && !disabled) {
			setLoading(true);
		}
	}, [data, error, loading, disabled]);

	const reqOptions: UseFetchOptionsType<RequestType> = { ...options, dependencies, disabled };
	const {
		loading: reqLoading,
		data: reqData,
		error: reqError,
	} = useHyperFetch(request, reqOptions);

	useEffect(() => {
		if (data || error) {
			return;
		}
		if (!reqLoading) {
			if (reqData) {
				setLoading(false);
				setData(reqData);
			}
			if (reqError) {
				setLoading(false);
				setError(error);
			}
		}
	}, [data, error, reqData, reqError, reqLoading]);

	return { data, error, loading };
}
