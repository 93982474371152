import { OrderV2Connection } from '@sixriver/fulfillment-api-schema';
import { gql } from 'graphql-tag';

import { PAGE_INFO_FRAGMENT } from '../../graphql';

export interface ExceptionsData {
	getOrders: OrderV2Connection;
}

export const EXCEPTIONS_QUERY = gql`
	query Orders(
		$after: String
		$first: Int
		$searchText: String
		$statuses: [JobStatus!]
		$isShorted: Boolean
		$isHealable: Boolean
	) {
		getOrders(
			after: $after
			first: $first
			searchText: $searchText
			statuses: $statuses
			isShorted: $isShorted
			isHealable: $isHealable
		) {
			edges {
				node {
					id
					externalId
					status
					createdAt
					expectedShipDate
					externalContainerId
					isShorted
					isHealable
					tasks {
						workArea {
							id
							name
						}
					}
					lines {
						edges {
							node {
								quantity
								doneQuantity
								status
							}
						}
					}
					totalLineQuantities {
						pickedUnits
						totalUnits
					}
					workflows {
						type
						status
					}
					healPlans {
						id
					}
				}
			}
			pageInfo {
				...PageInfo
			}
		}
	}
	${PAGE_INFO_FRAGMENT}
`;
