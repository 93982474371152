import { useCallback } from 'react';

import { useQueryState } from '../../../hooks/useQueryState';

export const useShuttleQueryState = () => {
	const [isNewRoute, setIsNewRoute] = useQueryState<true | undefined>('newShuttle', undefined);
	const [selectedShuttleId, setSelectedShuttleId] = useQueryState<string | undefined>(
		'shuttleId',
		undefined,
	);

	const clearShuttleQueryState = useCallback(() => {
		isNewRoute && setIsNewRoute(undefined);
		selectedShuttleId && setSelectedShuttleId(undefined);
	}, [isNewRoute, selectedShuttleId, setIsNewRoute, setSelectedShuttleId]);

	return {
		clearShuttleQueryState,
		isNewRoute,
		selectedShuttleId,
		setIsNewRoute,
		setSelectedShuttleId,
	};
};
