type LocalizationMessages = {
	induct: string;
	takeoff: string;
	meetingPoint: string;
	charge: string;
	preCharge: string;
};

export function getWorkflowColors(messages: LocalizationMessages) {
	return [
		{ baseType: 'induct', color: '#35cc30', type: messages.induct },
		{ baseType: 'takeoff', color: '#a7bc05', type: messages.takeoff },
		{ baseType: 'meeting-point', color: '#c53deb', type: messages.meetingPoint },
		{ baseType: 'charger', color: '#0000ff', type: messages.charge },
		{ baseType: 'charger', color: '#44c1ea', type: messages.preCharge },
		{ baseType: 'putaway', color: '#ffa500', type: 'Putaway' },
	];
}

function getWorkflowPointTypeAux(labels: { [key: string]: boolean }): string {
	let type = 'unknown';

	switch (true) {
		case labels['putaway']:
			type = 'putaway';
			break;
		case labels['induct']:
			type = 'induct';
			break;
		case labels['takeoff']:
			type = 'takeoff';
			break;
		case labels['preCharge'] || labels['postCharge'] || labels['charge']:
			type = 'charger';
			break;
		case labels['repick'] || labels['picking']:
			type = 'meeting-point';
			break;
		case labels['stageUpgrade']:
			type = 'stage-upgrade';
			break;
		case labels['postpick']:
			type = 'post-pick';
			break;
		default:
			type = 'custom';
	}

	return type;
}

export function getWorkflowPointType(labels: string[]): string {
	const labelObj = labels.reduce((a, l) => ((a[l] = true), a), {} as { [key: string]: boolean });
	const type = getWorkflowPointTypeAux(labelObj);
	return type;
}
