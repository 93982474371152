import { useFetch } from '@hyper-fetch/react';

import { shuttlesByMapChunk } from '../api';

export function useShuttleRoutes(mapChunkId: string) {
	const { loading, data, error, refetch } = useFetch(
		shuttlesByMapChunk.setQueryParams({
			filter: { where: { deleted: { neq: true }, mapChunkId } },
		}),
	);
	return { data, error, loading, refetch };
}
