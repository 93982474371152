import { IndexTable, Link } from '@sixriver/lighthouse-web-community';

import * as routes from '../../routes';

type NameCellProps = {
	id: string;
	name: string;
};

export function NameCell({ id, name }: NameCellProps) {
	return (
		<IndexTable.Cell>
			<Link url={routes.device(id)} removeUnderline>
				{name}
			</Link>
		</IndexTable.Cell>
	);
}
