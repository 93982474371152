import { Button } from '@sixriver/lighthouse-web-community';
import { useEffect, useState } from 'react';

import { Chuck } from './Chuck.type';
import { ChuckCard } from './ChuckCard';
import { SidePane } from './SidePane';
import sidePaneStyles from './SidePane.module.css';
import { useLocalization } from '../../hooks/useLocalization';

interface Props {
	chucks: Chuck[];
	filtered?: boolean;
	selectedId?: string;
	onSelect(id: string): void;
}

/**
 * This component displays a list of Chucks alongside/above the map. Pass
 * a selectedId to force it open.
 */
export function ChuckList({ chucks, filtered, selectedId, onSelect }: Props) {
	const { messages } = useLocalization();
	const [visible, setVisible] = useState(false);

	const matchedChucks = chucks.filter((chuck) => {
		return chuck.matched !== false;
	});

	useEffect(() => {
		if (selectedId || filtered) {
			setVisible(true);
		}
	}, [selectedId, filtered]);

	if (visible) {
		return (
			<SidePane
				title={`${messages.chucks} (${matchedChucks.length})`}
				onDismiss={() => setVisible(false)}
			>
				<ul className={sidePaneStyles.list}>
					{matchedChucks.map((chuck) => {
						return (
							<ChuckCard
								key={chuck.id}
								chuck={chuck}
								selected={selectedId === chuck.id}
								onSelect={onSelect}
							/>
						);
					})}
				</ul>
			</SidePane>
		);
	}

	return (
		<Button onClick={() => setVisible(true)} id="listButton">
			{messages.showChuckList}
		</Button>
	);
}
