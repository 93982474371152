import { LineV2, LineStatus } from '@sixriver/fulfillment-api-schema';
import { IndexTable } from '@sixriver/lighthouse-web-community';

import { NoData } from '../../../components/NoData';
import { useIsHealingEnabled } from '../../../hooks/useConfig';
import { useLocalization } from '../../../hooks/useLocalization';

// TODO: review reasons for accuracy and if needed
const distinctExceptionReasons = (lines: { edges: { node: LineV2 }[] }): LineStatus[] => {
	return Array.from(
		new Set(
			lines.edges
				.map(({ node: line }: any) => line.status || [])
				.flat()
				.filter((status: any) => {
					return [
						LineStatus.DamagedProduct,
						LineStatus.MissingProduct,
						LineStatus.WorkflowException,
					].includes(status);
				}),
		),
	);
};

export function TypesCell({ lines }: { lines: { edges: { node: LineV2 }[] } }) {
	const { messages } = useLocalization();
	const isHealingEnabled = useIsHealingEnabled();
	// TODO: review reasons for accuracy and if needed
	const reasons = distinctExceptionReasons(lines);

	return (
		<>
			{/* TODO: review this cell for accuracy */}
			{!isHealingEnabled && (
				<IndexTable.Cell>
					<div>
						{reasons.length === 1 ? (
							messages.exceptionTypes[reasons[0] as keyof typeof messages.exceptionTypes]
						) : reasons.length > 1 ? (
							messages.multiple
						) : (
							<NoData />
						)}
					</div>
				</IndexTable.Cell>
			)}
		</>
	);
}
