import { History, Location } from 'history';

import * as routes from '../../routes';

type LocationState = { from?: Location };

export const redirectUserAfterLogin = (history: History) => {
	const params = new URLSearchParams(history.location.search);
	const from = (history.location.state as LocationState)?.from || { pathname: '/' };

	const originalUri = params.get('original-uri');

	if (from.pathname.includes(routes.logout())) {
		history.push('/');
	} else if (originalUri) {
		const redirectUrl = new URL(originalUri);

		// Ensure the redirect URL is within the same origin.  If so,
		// redirect to the URL.  Otherwise, ignore it
		if (redirectUrl.origin === window.location.origin) {
			window.location.href = redirectUrl.href;
		} else {
			history.push('/');
		}
	} else {
		history.push(from);
	}
};
