import { CirclePlusMinor } from '@sixriver/lighthouse-icons';
import { Badge, Icon, Stack, Tooltip } from '@sixriver/lighthouse-web-community';

import { OutboundJobExceptionIcon } from '../../components/OutboundJobExceptionIcon/OutboundJobExceptionIcon';
import { useLocalization } from '../../hooks/useLocalization';

interface Props {
	job?: any;
}
export function OutboundJobStatusBadgeV2({ job }: Props): JSX.Element | null {
	const { messages } = useLocalization();

	if (!job) {
		return null;
	}

	const { workflows = [] } = job;
	const pickWorkflow = workflows.find((workflow) => workflow.type === 'PICK');
	const sortWorkflow = workflows.find((workflow) => workflow.type === 'SORT');
	const packWorkflow = workflows.find((workflow) => workflow.type === 'PACK');
	const healWorkflow = workflows.find((workflow) => workflow.type === 'HEAL');

	let badge = <Badge status="warning">{messages.unassigned}</Badge>;

	if (job.status === 'Ready') {
		if (sortWorkflow?.status === 'READY') {
			badge = <Badge status="info">{messages.readyToSort}</Badge>;
		}

		if (packWorkflow?.status === 'READY') {
			badge = <Badge status="info">{messages.readyToPack}</Badge>;
		}

		if (healWorkflow?.status === 'READY' && pickWorkflow?.status === 'DONE') {
			badge = <Badge>{messages.picked}</Badge>;
		}
	}

	if (job.status === 'InProgress') {
		if (pickWorkflow?.status === 'IN_PROGRESS') {
			badge = <Badge status="info">{messages.picking}</Badge>;
		}

		if (sortWorkflow?.status === 'IN_PROGRESS') {
			badge = <Badge status="info">{messages.sorting}</Badge>;
		}

		if (packWorkflow?.status === 'IN_PROGRESS') {
			badge = <Badge status="info">{messages.packing}</Badge>;
		}
	}

	if (job.status === 'Done') {
		if (pickWorkflow?.status === 'DONE') {
			badge = <Badge>{messages.picked}</Badge>;
		}

		if (sortWorkflow?.status === 'DONE') {
			badge = <Badge>{messages.sorted}</Badge>;
		}

		if (packWorkflow?.status === 'DONE') {
			badge = <Badge>{messages.packed}</Badge>;
		}
	}

	if (job.status === 'Canceled') {
		badge = <Badge>{messages.canceled}</Badge>;
	}

	if (job.status === 'Interrupted') {
		badge = <Badge>{messages.interrupted}</Badge>;
	}

	if (job.status === 'Unknown') {
		badge = <Badge status="warning">{messages.unknown}</Badge>;
	}

	let iconAwaitingInventory;

	if (job.isAwaitingInventory) {
		iconAwaitingInventory = (
			<Tooltip content={messages.orderAwaitingInventory} dismissOnMouseOut>
				<Icon source={CirclePlusMinor} color="warning" />
			</Tooltip>
		);
	}

	return (
		<Stack spacing="extraTight" wrap={false}>
			{badge}
			<OutboundJobExceptionIcon job={job} />
			{iconAwaitingInventory}
		</Stack>
	);
}
