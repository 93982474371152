import { MapStack } from '@sixriver/map-io';
import { useAuth, UserRole } from '@sixriver/react-support';
import { ShuttleRoute } from '@sixriver/task-coordinator-oas';
import { useEffect, useMemo } from 'react';

import { OpenShuttlePaneButton } from './OpenShuttlePaneButton';
import { ShuttleCard } from './ShuttleCard';
import { ShuttleRoutesList } from './ShuttleRoutesList';
import { useShuttleQueryState } from './useShuttleQueryState';
import { Error } from '../../../components/Error';
import { useLocalization } from '../../../hooks/useLocalization';
import { useShuttleRoutes } from '../../../hooks/useShuttleRoutes';
import { uuidv4 } from '../../../utils';
import { mapApi } from '../MapOptions';
import { MapSelection } from '../MapSelection';
import { SidePane } from '../SidePane';

interface Props {
	open: boolean;
	setOpen: (show: boolean) => void;
	map: MapStack;
	mapSelection?: MapSelection;
}

/**
 * This component displays a list of Shuttles alongside/above the map. Pass
 * a selectedId to force it open.
 */
export function ShuttleRoutes({ open, setOpen, map, mapSelection }: Props) {
	const { data, error, loading, refetch } = useShuttleRoutes(map.id as string);
	const { isNewRoute, setIsNewRoute, selectedShuttleId, setSelectedShuttleId } =
		useShuttleQueryState();

	const { isUserAllowed } = useAuth();
	const { messages } = useLocalization();

	const selectedShuttleRoute = useMemo(() => {
		return data?.find((shuttle) => shuttle.id === selectedShuttleId);
	}, [selectedShuttleId, data]);

	const shuttleRoutes = useMemo(() => data ?? [], [data]);

	const newShuttleRoute: ShuttleRoute = useMemo(() => {
		return {
			cycleType: 'loop',
			deleted: false,
			enabled: true,
			id: uuidv4(),
			mapChunkId: map.id as string,
			name: `Route ${shuttleRoutes.length + 1}`,
			points: [],
		};
	}, [map.id, shuttleRoutes.length]);

	// Clear the selected map points when the selected route changes
	useEffect(() => {
		if (!selectedShuttleId) {
			mapApi?.clearSelection();
		}
	}, [selectedShuttleId]);

	// Refresh the routes when the pane is opened
	useEffect(() => {
		if (open && !loading) {
			refetch();
		}
		// We only care abiout the open state, otherwise we'll be stuck in a loop
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open]);

	if (loading) {
		return <SidePane title={messages.shuttleRoutes.refreshing}></SidePane>;
	}

	if (error) {
		return (
			<SidePane title={messages.shuttleRoutes.error}>
				<Error message={error.message} />
			</SidePane>
		);
	}

	if (!isUserAllowed([UserRole.Admin, UserRole.WarehouseManager])) {
		return null;
	}

	if (!open) {
		return <OpenShuttlePaneButton onClick={() => setOpen(true)} />;
	}

	if (selectedShuttleRoute) {
		return (
			<ShuttleCard
				initialShuttleRoute={selectedShuttleRoute}
				mapSelection={mapSelection}
				refetch={refetch}
			/>
		);
	}

	if (isNewRoute) {
		return (
			<ShuttleCard
				initialShuttleRoute={newShuttleRoute}
				mapSelection={mapSelection}
				refetch={refetch}
			/>
		);
	}

	return (
		<ShuttleRoutesList
			shuttleRoutes={shuttleRoutes}
			onDismiss={() => {
				setOpen(false);
			}}
			onSelectShuttle={(id) => {
				setSelectedShuttleId(id);
			}}
			onNewShuttle={() => setIsNewRoute(true)}
		/>
	);
}
