import { Select, Stack, TextField } from '@sixriver/lighthouse-web-community';
import { ShuttleRoute } from '@sixriver/task-coordinator-oas';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Prompt } from 'react-router-dom';

import { ConfirmShuttleModal } from './ConfirmShuttle';
import { CycleTypeExplanation } from './CycleTypeExplanation';
import { CycleType, CycleTypeValuesArray } from './Shuttle.type';
import cardStyles from './ShuttleCard.module.css';
import { ShuttleCardFooter } from './ShuttleCardFooter';
import { ShuttlePointsList } from './ShuttlePointsList';
import { useShuttleQueryState } from './useShuttleQueryState';
import { addOrUpdateShuttle } from '../../../api';
import { Toggle } from '../../../components/Toggle';
import { useLocalization } from '../../../hooks/useLocalization';
import { useToast } from '../../../hooks/useToast';
import { mapApi } from '../MapOptions';
import { MapSelection } from '../MapSelection';
import { SidePane } from '../SidePane';

interface Props {
	initialShuttleRoute: ShuttleRoute | null;
	mapSelection?: MapSelection;
	refetch: () => void;
}

/**
 * This component displays shuttle point details
 */
export function ShuttleCard({ initialShuttleRoute, refetch, mapSelection }: Props) {
	const { clearShuttleQueryState, isNewRoute } = useShuttleQueryState();
	const { messages } = useLocalization();
	const [shuttleRoute, setShuttleRoute] = useState<ShuttleRoute>(
		initialShuttleRoute as ShuttleRoute,
	);
	const [confirmModalOpen, setConfirmModalOpen] = useState(false);
	const { showToast } = useToast();

	const modified = useMemo(() => {
		return JSON.stringify(shuttleRoute) !== JSON.stringify(initialShuttleRoute);
	}, [initialShuttleRoute, shuttleRoute]);

	const onDismiss = useCallback(() => {
		clearShuttleQueryState();
	}, [clearShuttleQueryState]);

	const setShuttleRouteProp = useCallback(
		<T extends keyof ShuttleRoute>(prop: T, value: ShuttleRoute[T]) => {
			setShuttleRoute((route) => ({ ...route, [prop]: value }));
		},
		[],
	);

	// Methods
	const onSave = useCallback(async () => {
		const result = await addOrUpdateShuttle.send({ data: shuttleRoute });

		if (!result.success) {
			showToast(messages.shuttleRouteSaveError, true);
			return;
		} else {
			await refetch();
			onDismiss();
			showToast(messages.shuttleRouteSaved);
		}
	}, [
		messages.shuttleRouteSaveError,
		messages.shuttleRouteSaved,
		onDismiss,
		refetch,
		showToast,
		shuttleRoute,
	]);

	const onDelete = useCallback(async () => {
		const result = await addOrUpdateShuttle.send({ data: { ...shuttleRoute, deleted: true } });

		if (!result.success) {
			showToast(messages.shuttleRouteSaveFailed, true);
			return;
		}

		await refetch();
		onDismiss();
	}, [messages.shuttleRouteSaveFailed, onDismiss, refetch, showToast, shuttleRoute]);

	const loopNames: Record<string, string> = {
		loop: messages.shuttleRouteLoop,
		oneShot: messages.shuttleRouteOneShot,
		pingPong: messages.shuttleRoutePingPong,
	};
	const cycleArray = CycleTypeValuesArray.map((cycle) => ({
		label: loopNames[cycle],
		value: cycle,
	}));

	const onConfirmRemoval = useCallback(async () => {
		setConfirmModalOpen(false);
		mapApi?.clearSelection();
		onDelete();
	}, [onDelete]);

	// When a new route is selected, update the form
	useEffect(() => {
		setShuttleRoute(initialShuttleRoute as ShuttleRoute);
	}, [initialShuttleRoute]);

	return (
		<>
			<ConfirmShuttleModal
				open={confirmModalOpen}
				onDismiss={() => setConfirmModalOpen(false)}
				onConfirm={onConfirmRemoval}
				name={shuttleRoute.name}
			/>
			<Prompt when={modified} message="You have unsaved changes. Are you sure you want to leave?" />
			<SidePane
				title={shuttleRoute.name}
				onBack={() => onDismiss()}
				actions={
					<ShuttleCardFooter
						modified={modified}
						valid={shuttleRoute.points.length > 0}
						onSave={onSave}
						onDelete={() => setConfirmModalOpen(true)}
						canDelete={!isNewRoute}
					/>
				}
			>
				<div className={cardStyles.outer}>
					<div className={cardStyles.content}>
						<Stack vertical>
							<TextField
								autoComplete="off"
								disabled={!shuttleRoute.enabled}
								label={messages.shuttleRouteName}
								value={shuttleRoute.name}
								name={messages.shuttleRouteName}
								maxLength={20}
								onChange={(value) => setShuttleRouteProp('name', value)}
							/>
							<Toggle
								checked={shuttleRoute.enabled}
								label={
									shuttleRoute.enabled
										? messages.shuttleRouteEnabled
										: messages.shuttleRouteDisabled
								}
								onCheck={(value) => setShuttleRouteProp('enabled', value)}
							/>
							<Select
								label={messages.shuttleRouteCycleType.title}
								labelInline
								disabled={!shuttleRoute.enabled}
								options={cycleArray}
								value={shuttleRoute.cycleType}
								onChange={(selected: CycleType) => setShuttleRouteProp('cycleType', selected)}
								helpText={<CycleTypeExplanation type={shuttleRoute.cycleType} />}
							/>
							<ShuttlePointsList
								points={shuttleRoute.points}
								setPoints={(points) => setShuttleRouteProp('points', points)}
								mapSelection={mapSelection}
								cycleType={shuttleRoute.cycleType}
							/>
						</Stack>
					</div>
				</div>
			</SidePane>
		</>
	);
}
