import { Modal, Text } from '@sixriver/lighthouse-web-community';

import { useLocalization } from '../../../hooks/useLocalization';

interface ConfirmShuttleProps {
	name: string;
	open: boolean;
	onDismiss: () => void;
	onConfirm: () => void;
}
export function ConfirmShuttleModal({ name, open, onDismiss, onConfirm }: ConfirmShuttleProps) {
	const { messages, translate } = useLocalization();
	return (
		<Modal
			open={open}
			onClose={onDismiss}
			title={messages.shuttleRouteDeleteModal.title}
			primaryAction={{
				content: messages.shuttleRouteDeleteModal.confirm,
				destructive: true,
				onAction: onConfirm,
			}}
			secondaryActions={[
				{
					content: messages.shuttleRouteDeleteModal.cancel,
					onAction: onDismiss,
				},
			]}
		>
			<Modal.Section>
				<Text as="p" variant="bodyMd">
					{translate(messages.shuttleRouteDeleteModal.description, { name })}
				</Text>
			</Modal.Section>
		</Modal>
	);
}
