import { LineV2 } from '@sixriver/fulfillment-api-schema';
import { IndexTable, Link } from '@sixriver/lighthouse-web-community';

import * as routes from '../../../routes';
import { NoData } from '../../NoData';

interface Props {
	line: LineV2;
}

export function ProductIdCell({ line }: Props): JSX.Element {
	if (line.productId) {
		return (
			<IndexTable.Cell>
				<span data-testid={`line-${line.id}`}></span>
				<Link url={routes.product(line.productId)} removeUnderline>
					{line.productCustomerIdentifier}
				</Link>
			</IndexTable.Cell>
		);
	}

	return (
		<IndexTable.Cell>
			<NoData />
		</IndexTable.Cell>
	);
}
