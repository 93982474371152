import {
	JobOrderBy,
	JobOrderByFields,
	JobStatus,
	OrderByDirection,
	PickStrategy,
	WorkOrderType,
} from '@sixriver/fulfillment-api-schema';
import {
	AppliedFilterInterface,
	FilterInterface,
	Filters,
} from '@sixriver/lighthouse-web-community';
import { isEmpty, noop } from 'lodash';

import { Alert, AlertsFilter } from './AlertsFilter';
import { PickStrategyFilter } from './PickStrategyFilter';
import { StatusesFilter } from './StatusesFilter';
import { MapChunk } from '../../../api';
import { CutoffFilterV2 } from '../../../components/CutoffFilter/CutoffFilterV2';
import { SortChoice, SortBy } from '../../../components/SortBy';
// import { useEquipmentTypes } from '../../../hooks/useConfig';
import { useLocalization } from '../../../hooks/useLocalization';
import { usePickStrategyName } from '../../../hooks/usePickStrategies';
// import { EquipmentTypesFilter } from '../../Orders/OrdersFilters';
import { WorkOrderTypesFilter } from '../../Orders/OrdersFilters/WorkOrderTypeFilter';

function convertToJobOrderBy(sortStrings: string[]): JobOrderBy[] {
	return sortStrings.map((option) => {
		const [field, direction] = option.split(' ') as [JobOrderByFields, OrderByDirection];
		return { direction, field };
	});
}

const STATUSES_FILTER_KEY = 'statuses';
const ALERTS_FILTER_KEY = 'alerts';
const CUTOFF_KEY = 'cutoff';
const PICK_STRATEGY_KEY = 'pickStrategy';
const WORK_ORDER_TYPE_KEY = 'workOrderType';
const WORKAREA_KEY = 'workArea';
const IDS_KEY = 'ids';
const USED_EQUIPMENT_TYPES_FILTER_KEY = 'usedEquipmentTypes';

interface OutboundJobsFiltersProps {
	queryValue?: string;
	onQueryChange?: (value: string) => void;
	onClearAll?: () => void;
	selectedSort?: JobOrderBy[];
	onSortChange?: (value: JobOrderBy[]) => void;
	selectedCutoffDate?: Date;
	cutoffDates?: Date[];
	onCutoffChange: (value: Date) => void;
	onCutoffRemove: () => void;
	selectedStatuses: JobStatus[];
	onStatusChange: (selected: JobStatus[]) => void;
	onStatusRemove: () => void;
	selectedAlerts: Alert[];
	onAlertChange: (selected: Alert[]) => void;
	onAlertRemove: () => void;
	showPickStrategy?: boolean;
	selectedPickStrategies: PickStrategy[];
	availablePickStrategies: PickStrategy[];
	onPickStrategyChange: (selected: PickStrategy[]) => void;
	onPickStrategyRemove: () => void;
	showWorkOrderType?: boolean;
	selectedWorkOrderTypes: WorkOrderType[];
	onWorkOrderTypeChange: (selected: WorkOrderType[]) => void;
	onWorkOrderTypeRemove: () => void;
	selectedWorkAreaIds: string[];
	workAreas: MapChunk[];
	onWorkAreaRemove: () => void;
	ids?: string[];
	onIdsRemove?: () => void;
	selectedUsedEquipmentTypes: string[];
	onUsedEquipmentTypesChange: (selected: string[]) => void;
	onUsedEquipmentTypesRemove: () => void;
	isOnChuck?: true | undefined;
	isOnChuckChange: (value: true | undefined) => void;
}

export function OutboundJobsFilters({
	queryValue,
	onQueryChange = noop,
	onClearAll = noop,
	selectedSort = [],
	onSortChange = noop,
	selectedCutoffDate,
	cutoffDates = [],
	onCutoffChange,
	onCutoffRemove,
	selectedStatuses,
	onStatusChange,
	onStatusRemove,
	selectedAlerts,
	onAlertChange,
	onAlertRemove,
	showPickStrategy = false,
	selectedPickStrategies,
	availablePickStrategies,
	onPickStrategyChange,
	onPickStrategyRemove,
	showWorkOrderType,
	selectedWorkOrderTypes,
	onWorkOrderTypeChange,
	onWorkOrderTypeRemove,
	selectedWorkAreaIds,
	workAreas,
	onWorkAreaRemove,
	ids = [],
	onIdsRemove = noop,
	selectedUsedEquipmentTypes,
	// onUsedEquipmentTypesChange,
	onUsedEquipmentTypesRemove,
	isOnChuck,
	isOnChuckChange,
}: OutboundJobsFiltersProps) {
	const { messages, formatDateTime } = useLocalization();
	const getPickStrategyName = usePickStrategyName();
	// const usedEquipmentTypes = useEquipmentTypes();

	/**
	 * Sort choices
	 */
	const sortChoices: SortChoice[] = [
		{
			label: messages.sortOptions.dateReceivedDesc,
			value: `${JobOrderByFields.CreatedAt} ${OrderByDirection.Desc}`,
		},
		{
			label: messages.sortOptions.dateReceivedAsc,
			value: `${JobOrderByFields.CreatedAt} ${OrderByDirection.Asc}`,
		},
		{
			label: messages.sortOptions.cutoffAsc,
			value: `${JobOrderByFields.ExpectedShipDate} ${OrderByDirection.Asc}`,
		},
		{
			label: messages.sortOptions.cutoffDesc,
			value: `${JobOrderByFields.ExpectedShipDate} ${OrderByDirection.Desc}`,
		},
	];

	/**
	 * Filters
	 */
	/**
	 * Super hacky way to allow some sites to abuse carrier cutoff and have hundreds per day
	 * and still show them the cutoff filter.   Through trial and error testing, it appears
	 * that React starts chocking when it has to do more that 8000 items in a list.
	 * So if the list has less than the max show the filter.
	 * Big query shows the largest sites currently are <7500 in a 7 day period.
	 */

	const maxLimit = 8000;
	const showCutoffFilter = cutoffDates.length <= maxLimit;
	const filters: FilterInterface[] = [
		...(showCutoffFilter
			? [
					{
						filter: (
							<CutoffFilterV2
								value={selectedCutoffDate}
								onChange={(value) => onCutoffChange(new Date(value))}
								cutoffDates={cutoffDates || []}
							/>
						),
						key: CUTOFF_KEY,
						label: messages.carrierCutoff,
						shortcut: true,
					},
			  ]
			: []),
		...(showWorkOrderType
			? [
					{
						filter: (
							<WorkOrderTypesFilter
								selected={selectedWorkOrderTypes}
								onChange={onWorkOrderTypeChange}
							/>
						),
						key: WORK_ORDER_TYPE_KEY,
						label: messages.workOrderType,
						shortcut: true,
					},
			  ]
			: []),
		{
			filter: <StatusesFilter selected={selectedStatuses} onChange={onStatusChange} />,
			key: STATUSES_FILTER_KEY,
			label: messages.jobStatus,
			shortcut: true,
		},
		{
			filter: <AlertsFilter selected={selectedAlerts} onChange={onAlertChange} />,
			key: ALERTS_FILTER_KEY,
			label: messages.issues,
			shortcut: true,
		},
		// ...(usedEquipmentTypes.length > 0
		// 	? [
		// 			{
		// 				filter: (
		// 					<EquipmentTypesFilter
		// 						selected={selectedUsedEquipmentTypes}
		// 						onChange={onUsedEquipmentTypesChange}
		// 					/>
		// 				),
		// 				key: USED_EQUIPMENT_TYPES_FILTER_KEY,
		// 				label: messages.equipment,
		// 				shortcut: true,
		// 			},
		// 	  ]
		// 	: []),
		...(showPickStrategy
			? [
					{
						filter: (
							<PickStrategyFilter
								selected={selectedPickStrategies}
								options={availablePickStrategies}
								onChange={onPickStrategyChange}
							/>
						),
						key: PICK_STRATEGY_KEY,
						label: messages.pickStrategy,
						shortcut: true,
					},
			  ]
			: []),
	];

	const appliedFilters: AppliedFilterInterface[] = [];

	if (isOnChuck) {
		appliedFilters.push({
			key: STATUSES_FILTER_KEY,
			label: `${messages.onChuck}`,
			onRemove: () => isOnChuckChange(undefined),
		});
	}

	if (!isEmpty(selectedStatuses)) {
		appliedFilters.push({
			key: STATUSES_FILTER_KEY,
			label: selectedStatuses
				.map((status) => {
					switch (status) {
						case JobStatus.Unassigned:
							return messages.unassigned;
						case JobStatus.Picking:
							return messages.picking;
						case JobStatus.Picked:
							return messages.picked;
						case JobStatus.Sortable:
							return messages.readyToSort;
						case JobStatus.Sorting:
							return messages.sorting;
						case JobStatus.Sorted:
							return messages.sorted;
						case JobStatus.Packable:
							return messages.readyToPack;
						case JobStatus.Packing:
							return messages.packing;
						case JobStatus.Packed:
							return messages.packed;
						case JobStatus.Interrupted:
							return messages.interrupted;
						case JobStatus.Canceled:
							return messages.canceled;
						default:
							return messages.unknown;
					}
				})
				.join(', '),
			onRemove: onStatusRemove,
		});
	}

	if (!isEmpty(selectedAlerts)) {
		appliedFilters.push({
			key: ALERTS_FILTER_KEY,
			label: selectedAlerts
				.map((alert) => {
					switch (alert) {
						case Alert.Late:
							return messages.late;
						case Alert.Exception:
							return messages.exception;
						case Alert.AwaitingInventory:
							return messages.awaitingInventory;
						case Alert.IsResolution:
							return messages.resolutionJobs;
						default:
							return messages.unknown;
					}
				})
				.join(', '),
			onRemove: onAlertRemove,
		});
	}

	if (selectedCutoffDate) {
		appliedFilters.push({
			key: CUTOFF_KEY,
			label: messages.carrierCutoff + ': ' + formatDateTime(selectedCutoffDate),
			onRemove: onCutoffRemove,
		});
	}

	if (!isEmpty(selectedPickStrategies)) {
		appliedFilters.push({
			key: PICK_STRATEGY_KEY,
			label: selectedPickStrategies.map((pickType) => getPickStrategyName(pickType)).join(', '),
			onRemove: onPickStrategyRemove,
		});
	}

	if (!isEmpty(selectedWorkOrderTypes)) {
		appliedFilters.push({
			key: WORK_ORDER_TYPE_KEY,
			label:
				selectedWorkOrderTypes[0] === WorkOrderType.InventoryTransferToNode
					? messages.workOrderTypes.transfer
					: messages.workOrderTypes.ecommerce,
			onRemove: onWorkOrderTypeRemove,
		});
	}

	if (!isEmpty(selectedWorkAreaIds)) {
		appliedFilters.push({
			key: WORKAREA_KEY,
			label: workAreas
				.filter((area) => selectedWorkAreaIds.includes(area.mapChunkId))
				.map((area, i) => area.displayName || `${messages.workArea} ${i + 1}`)
				.join(', '),
			onRemove: onWorkAreaRemove,
		});
	}

	if (!isEmpty(ids)) {
		appliedFilters.push({
			key: IDS_KEY,
			label: ids.join(', '),
			onRemove: onIdsRemove,
		});
	}

	if (!isEmpty(selectedUsedEquipmentTypes)) {
		appliedFilters.push({
			key: USED_EQUIPMENT_TYPES_FILTER_KEY,
			label: selectedUsedEquipmentTypes.join(','),
			onRemove: onUsedEquipmentTypesRemove,
		});
	}

	return (
		<Filters
			queryValue={queryValue}
			queryPlaceholder={messages.filterOutboundJobs}
			onQueryChange={onQueryChange}
			onQueryClear={() => onQueryChange('')}
			filters={filters}
			appliedFilters={appliedFilters}
			onClearAll={onClearAll}
		>
			<SortBy
				choices={sortChoices}
				selected={selectedSort.map((sort) => `${sort.field} ${sort.direction}`)}
				onChange={(selected) => onSortChange(convertToJobOrderBy(selected))}
			/>
		</Filters>
	);
}
