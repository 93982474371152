import { gql } from 'graphql-tag';

export const ORDER_QUERY = gql`
	query GetOrder($id: String!) {
		job(id: $id) {
			id
			externalId
			expectedShipDate
			externalContainerId
			createdAt
			isShorted
			isHealable
			status
			lines {
				edges {
					node {
						quantity
						doneQuantity
					}
				}
			}
			tasks {
				workArea {
					id
					name
				}
			}
		}
	}
`;
