import { Modal, Page, Spinner, TextStyle } from '@sixriver/lighthouse-web-community';
import { useAuth, UserRole, useSiteName } from '@sixriver/react-support';
import { useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
	DeviceEditFormData,
	DeviceEditFormInfo,
	validateDeviceName,
	renameDevice,
} from './Device.utils';
import { DeviceEditForm } from './DeviceEditForm';
import { DeviceState } from '../../api';
import { GlobalErrorType } from '../../api/client';
import { Error as ShowError } from '../../components/Error';
import {
	getDeviceType,
	useAvailableMfp,
	getDeviceState,
	isVirtualDeviceName,
} from '../../hooks/useAvailableMfps';
import { useExperimentalFlags, useMfpConfigForDevice, useSsid } from '../../hooks/useConfig';
import { useLocalization } from '../../hooks/useLocalization';
import { useToast } from '../../hooks/useToast';
import * as routes from '../../routes';

export function DeviceEdit() {
	const experimentalFlags = useExperimentalFlags();
	const { isUserAllowed } = useAuth();

	const history = useHistory();
	// Getting Data
	const params = useParams<{ deviceId: string }>();
	const { messages, translate } = useLocalization();
	const siteName = useSiteName();

	const deviceId = params?.deviceId;
	const { data: device, error: fetchingDeviceError } = useAvailableMfp(deviceId);

	const { data: mfpConfig, error: fetchingMfpConfigError } = useMfpConfigForDevice(device);
	const ssid = useSsid();

	// Form related
	const { showToast } = useToast();
	const [newName, setNewName] = useState('');
	const [errors, setErrors] = useState<{ name?: string }>({});
	const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
	const [isExecutingAction, setIsExecutingAction] = useState(false);
	const [isDeviceCannotBeRestartedModalOpen, setIsDeviceCannotBeRestartedModalOpen] =
		useState(false);
	const [isDeviceRenamedModalOpen, setIsDeviceRenamedModalOpen] = useState(false);

	const closeConfirmModal = useCallback(
		() => setIsConfirmModalOpen(false),
		[setIsConfirmModalOpen],
	);
	const closeDeviceCannotBeRestartedModal = useCallback(
		() => setIsDeviceCannotBeRestartedModalOpen(false),
		[setIsDeviceCannotBeRestartedModalOpen],
	);

	const save = useCallback(async () => {
		if (!device || getDeviceState(device) !== DeviceState.NotInUse) {
			closeConfirmModal();
			setIsDeviceCannotBeRestartedModalOpen(true);
			return;
		}
		setIsExecutingAction(true);
		try {
			await renameDevice(siteName, mfpConfig!.name, newName);
			setIsExecutingAction(false);
			closeConfirmModal();
			setIsDeviceRenamedModalOpen(true);
		} catch (error) {
			setIsExecutingAction(false);
			closeConfirmModal();
			showToast(messages.errorToast, true);
		}
	}, [
		closeConfirmModal,
		setIsDeviceCannotBeRestartedModalOpen,
		siteName,
		device,
		mfpConfig,
		setIsExecutingAction,
		newName,
		showToast,
		messages.errorToast,
	]);

	const submitForm = useCallback(
		({ name }: DeviceEditFormData) => {
			if (name === '') {
				setErrors({ name: translate(messages.fieldRequired, { field: messages.name }) as string });
				return;
			}
			const nameValidationError = validateDeviceName(name, siteName);
			if (nameValidationError !== undefined) {
				let message = '';
				switch (nameValidationError) {
					case 'charset':
						message = messages.invalidDeviceName.charsetError;
						break;
					case 'length':
						message = messages.invalidDeviceName.lengthError;
						break;
					case 'siteName':
						message = translate(messages.invalidDeviceName.siteNameError, { siteName }) as string;
						break;
				}
				setErrors({ name: message });
				return;
			}
			setNewName(name);
			setIsConfirmModalOpen(true);
		},
		[setErrors, setIsConfirmModalOpen, setNewName, messages, translate, siteName],
	);

	const gotoDevicePage = useCallback(() => {
		history.push(routes.device(deviceId));
	}, [history, deviceId]);

	// Rendering

	if (fetchingDeviceError) {
		let fetchError = fetchingDeviceError;
		if (Object.hasOwn(fetchingDeviceError, 'error')) {
			fetchError = (fetchingDeviceError as object as { error: GlobalErrorType }).error;
		}
		let message = fetchError.message;
		if (fetchError.status === 404) {
			message = messages.deviceNotFound;
		}
		return <ShowError message={message} />;
	}

	if (fetchingMfpConfigError) {
		let fetchError = fetchingMfpConfigError;
		if (Object.hasOwn(fetchingMfpConfigError, 'error')) {
			fetchError = (fetchingMfpConfigError as object as { error: GlobalErrorType }).error;
		}
		let message = fetchError.message;
		if (fetchError.status === 404) {
			message = messages.mfpConfigNotFound;
		}
		return <ShowError message={message} />;
	}

	if (!device || !mfpConfig) {
		return (
			<Page title={messages.gettingDeviceData}>
				<Spinner />
			</Page>
		);
	}

	// At this point we have both the device data and it's mfpConfig

	// We check if the device can really be edited and if not show an error
	const cannotEdit = getDeviceState(device) !== DeviceState.NotInUse;
	const isVirtualDevice = isVirtualDeviceName(mfpConfig.name, siteName);

	let cannotEditMessage = '';
	if (cannotEdit) {
		cannotEditMessage = messages.cannotEditDevice.status;
	}
	if (isVirtualDevice) {
		cannotEditMessage = messages.cannotEditDevice.virtualDevice;
	}
	if (
		!experimentalFlags.includes('EDIT_DEVICE') ||
		!isUserAllowed([UserRole.Admin, UserRole.WarehouseManager])
	) {
		cannotEditMessage = messages.cannotEditDevice.access;
	}
	if (cannotEditMessage) {
		return <ShowError message={cannotEditMessage} />;
	}

	// At this point we know that the device can be edited so we show the edit form
	const info: DeviceEditFormInfo = {
		device,
		lastCalibratedAt: mfpConfig.calibrationData.calibratedAt,
		model: getDeviceType(mfpConfig),
		network: ssid ?? '-',
	};

	const data: DeviceEditFormData = {
		name: mfpConfig.name,
	};

	return (
		<Page
			title={messages.editDevice}
			breadcrumbs={[
				{
					content: messages.device,
					url: routes.device(params?.deviceId),
				},
			]}
		>
			<>
				<DeviceEditForm mode="edit" onSubmit={submitForm} data={data} info={info} errors={errors} />

				<Modal
					title={messages.modalRestartRequired.title}
					open={isConfirmModalOpen}
					onClose={closeConfirmModal}
					primaryAction={{
						content: isExecutingAction
							? messages.modalRestartRequired.acting
							: messages.yesContinue,
						destructive: true,
						loading: isExecutingAction,
						onAction: save,
					}}
					secondaryActions={[
						{
							content: messages.noCancel,
							destructive: false,
							onAction: closeConfirmModal,
						},
					]}
				>
					<Modal.Section>
						<p>
							{translate(messages.modalRestartRequired.changingNameRequiresRestart, {
								currentName: mfpConfig.name,
								newName,
							})}
						</p>
						<br />
						<p>{messages.modalRestartRequired.inAddition}</p>
						<ul>
							<li>{messages.modalRestartRequired.deviceNamePlateNeedsToChange}</li>
							<li>
								{translate(messages.modalRestartRequired.qrCodesWarning, {
									name: mfpConfig.name,
								})}
							</li>
						</ul>
						<p>{messages.wouldYouLikeToContinue}</p>
					</Modal.Section>
				</Modal>

				<Modal
					title={translate(messages.modalCannotRestart.title, { name: mfpConfig.name })}
					open={isDeviceCannotBeRestartedModalOpen}
					onClose={closeDeviceCannotBeRestartedModal}
					primaryAction={{
						content: messages.okay,
						onAction: closeDeviceCannotBeRestartedModal,
					}}
				>
					<Modal.Section>
						<TextStyle>{messages.modalCannotRestart.content}</TextStyle>
					</Modal.Section>
				</Modal>

				<Modal
					title={messages.success}
					open={isDeviceRenamedModalOpen}
					onClose={gotoDevicePage}
					primaryAction={{
						content: messages.okay,
						onAction: gotoDevicePage,
					}}
				>
					<Modal.Section>
						<TextStyle>
							{translate(messages.deviceRenamed, { name: mfpConfig.name, newName })}
						</TextStyle>
					</Modal.Section>
				</Modal>
			</>
		</Page>
	);
}
