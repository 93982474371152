import { gql } from 'graphql-tag';

export const ORDER_QUERY = gql`
	query GetOrder($id: String!) {
		job(id: $id) {
			id
			externalId
			isTerminal
			isOrder
			isLate
			isAwaitingInventory
			pickStrategy
			status
			stagingIdentifier
			expectedShipDate
			createdAt
			updatedAt
			completedAt
			isShorted
			isHealable
			lines {
				edges {
					node {
						id
						status
						quantity
						doneQuantity
						completedAt
						productId
						productCustomerIdentifier
						productName
						isShorted
						customer {
							name
						}
					}
				}
			}
			totalLines {
				count
			}
			totalLineQuantities {
				inProgressUnits
				completedUnits
				pickedUnits
				totalUnits
				unassignedUnits
				completedLines
				packedUnits
			}
			servicedBySortations {
				id
				externalContainerId
				type
				pickStrategy
			}
			pickingJobs {
				id
				isHealJob
				status
				createdAt
				pickStrategy
				type
			}
			packouts {
				edges {
					node {
						completedAt
					}
				}
			}
			healPlans {
				status
			}
			tasks {
				id
				type
				completedAt
			}
			allowedDeviceClasses
			usedDeviceClasses
			workflows {
				type
				status
			}
		}
	}
`;
