import { InputMaybe, QueryJobsV2Args, Scalars } from '@sixriver/fulfillment-api-schema';
import { JobConnection } from '@sixriver/warehouse-api-graphql-types';
import { gql } from 'graphql-tag';
import { useMemo } from 'react';

import { usePollingQuery } from './usePollingQuery';

export interface StatusCounts {
	totalUnits: number;
	pickedUnits: number;
	inProgressUnits: number;
	completedLines: number;
	totalLines: number;
	totalJobs: number;
}

type WarehouseQueryJobsV2Args = QueryJobsV2Args & {
	isOnChuck?: InputMaybe<Scalars['Boolean']['input']>;
};

export function useJobsCounts(args: WarehouseQueryJobsV2Args, visible?: boolean): StatusCounts {
	const query = gql`
		query OutboundJobs(
			$mfpIds: [String!]
			$createdAtFrom: DateTime
			$after: String
			$first: Int
			$ids: [String!]
			$searchText: String
			$statuses: [JobStatus!]
			$isLate: Boolean
			$isShorted: Boolean
			$isAwaitingInventory: Boolean
			$isHealJob: Boolean
			$completedAtFrom: DateTime
			$completedAtTo: DateTime
			$expectedShipDateFrom: DateTime
			$expectedShipDateTo: DateTime
			$pickStrategies: [PickStrategy!]
			$orderBy: [JobOrderBy!]
			$workOrderTypes: [WorkOrderType!]
			$workAreaIds: [String!]
			$usedEquipmentTypes: [String!]
			$isOnChuck: Boolean
		) {
			jobs(
				mfpIds: $mfpIds
				after: $after
				first: $first
				ids: $ids
				searchText: $searchText
				statuses: $statuses
				isLate: $isLate
				isShorted: $isShorted
				isAwaitingInventory: $isAwaitingInventory
				isHealJob: $isHealJob
				completedAtFrom: $completedAtFrom
				completedAtTo: $completedAtTo
				expectedShipDateFrom: $expectedShipDateFrom
				expectedShipDateTo: $expectedShipDateTo
				createdAtFrom: $createdAtFrom
				pickStrategies: $pickStrategies
				orderBy: $orderBy
				workOrderTypes: $workOrderTypes
				workAreaIds: $workAreaIds
				isOrder: false
				isOnChuck: $isOnChuck
				usedEquipmentTypes: $usedEquipmentTypes
			) {
				edges {
					node {
						status
						isLate
						totalLines {
							count
						}
						totalLineQuantities {
							completedLines
							totalUnits
							pickedUnits
							inProgressUnits
						}
					}
				}
			}
		}
	`;

	const [{ data }] = usePollingQuery<{ jobs: JobConnection }>({
		context: useMemo(
			() => ({
				useWarehouseApi: true,
			}),
			[],
		),
		pause: !visible,
		pollInterval: 5000,
		query,
		variables: {
			...args,
		}, // 5 seconds
	});

	if (data && data.jobs?.edges) {
		let totalUnits = 0;
		let pickedUnits = 0;
		let inProgressUnits = 0;
		let completedLines = 0;
		let totalLines = 0;

		for (const jobEdge of data.jobs.edges) {
			const { node: job } = jobEdge;
			totalUnits += job.totalLineQuantities.totalUnits;
			pickedUnits += job.totalLineQuantities.pickedUnits;
			inProgressUnits += job.totalLineQuantities.inProgressUnits;
			completedLines += job.totalLineQuantities.completedLines;
			totalLines += job.totalLines.count;
		}

		return {
			completedLines,
			inProgressUnits,
			pickedUnits,
			totalJobs: data.jobs.edges.length,
			totalLines,
			totalUnits,
		};
	}

	return {
		completedLines: 0,
		inProgressUnits: 0,
		pickedUnits: 0,
		totalJobs: 0,
		totalLines: 0,
		totalUnits: 0,
	};
}
