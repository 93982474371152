import { Button } from '@sixriver/lighthouse-web-community';

import { useLocalization } from '../../../hooks/useLocalization';

interface OpenShuttlePaneButtonProps {
	onClick: () => void;
}

export function OpenShuttlePaneButton({ onClick }: OpenShuttlePaneButtonProps) {
	const { messages } = useLocalization();

	return (
		<Button onClick={onClick} id="listShuttleButton">
			{messages.showShuttleList}
		</Button>
	);
}
