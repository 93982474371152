import { useDebouncedValue } from '@shopify/react-hooks';
import { QueryOrdersV2Args } from '@sixriver/fulfillment-api-schema';
import { Card, Stack, Page, Layout, Pagination } from '@sixriver/lighthouse-web-community';
import { useCallback, useMemo, useState } from 'react';

import { ExceptionsData, EXCEPTIONS_QUERY } from './Exceptions.graphql';
import { ExceptionsFilters } from './ExceptionsFilters';
import { ExceptionsAction, ExceptionsTable } from './ExceptionsTable';
import { PrintLabelsBanner } from './PrintLabelsBanner';
import { AutoRefresh } from '../../components/AutoRefresh';
import { Error } from '../../components/Error';
import { ErrorBanner } from '../../components/ErrorBanner';
import { TimezoneFooter } from '../../components/TimezoneFooter';
import { getPageSize } from '../../helpers/page-size';
import { MIN_QUERY_LENGTH } from '../../helpers/table';
import { useApplyManualPickHealPlan } from '../../hooks/useHealPlan';
import { useLocalization } from '../../hooks/useLocalization';
import { usePolling } from '../../hooks/usePolling';
import { usePollingQuery } from '../../hooks/usePollingQuery';
import { useArrayQueryState, useQueryState } from '../../hooks/useQueryState';

const PAGE_SIZE = getPageSize();
const SEARCH_TEXT_KEY = 'query';
const PAGINATION_CURSORS_KEY = 'paginationCursors';

export function Exceptions() {
	const { messages } = useLocalization();
	const { pollingEnabled, togglePolling, queryPollInterval } = usePolling();
	const [isErrorBannerVisible, setIsErrorBannerVisible] = useState(false);
	const [paginationCursors, setPaginationCursors] = useArrayQueryState<string[]>(
		PAGINATION_CURSORS_KEY,
		[],
	);
	const [searchText, setSearchText] = useQueryState(SEARCH_TEXT_KEY, '');
	const debouncedSearchText = useDebouncedValue(searchText);
	const [didPrintLabels, setDidPrintLabels] = useState(false);
	const [totalCount] = useState(0);
	const [selectable] = useState(true);
	const [actions] = useState<ExceptionsAction[]>([ExceptionsAction.ManuallyResolve]);

	/**
	 * Queries
	 */
	const [{ fetching: ordersFetching, data: ordersData, error: ordersError }, refetchOrders] =
		usePollingQuery<ExceptionsData, QueryOrdersV2Args>({
			context: useMemo(
				() => ({
					useWarehouseApi: true,
				}),
				[],
			),
			pollInterval: queryPollInterval,
			query: EXCEPTIONS_QUERY,
			variables: {
				after: paginationCursors[0],
				first: PAGE_SIZE,
				isHealable: true,
				isShorted: true,
				searchText:
					debouncedSearchText.length >= MIN_QUERY_LENGTH ? debouncedSearchText : undefined,
			},
		});

	const orders = ordersData?.getOrders.edges;
	const ordersPageInfo = ordersData?.getOrders.pageInfo;
	const loading = ordersFetching;
	const error = ordersError;

	/**
	 * Pagination
	 * */
	const handleOnNextPage = useCallback(() => {
		const endCursor = ordersPageInfo?.endCursor ?? undefined;
		if (endCursor) {
			setPaginationCursors([endCursor].concat(paginationCursors));
		}
	}, [ordersPageInfo?.endCursor, paginationCursors, setPaginationCursors]);

	const handleOnPreviousPage = useCallback(() => {
		setPaginationCursors(paginationCursors.slice(1));
	}, [paginationCursors, setPaginationCursors]);

	/**
	 * Healing mutations
	 */
	const { applyManualHealPlan } = useApplyManualPickHealPlan();
	const handleManuallyResolve = useCallback(
		async (ids: string[]) => {
			await Promise.all(ids.map((id) => applyManualHealPlan(id)));
			setDidPrintLabels(true);
			await refetchOrders();
		},
		[applyManualHealPlan, refetchOrders],
	);

	return error ? (
		<Error graphQLError={error} />
	) : (
		<Page fullWidth title={messages.exceptions}>
			<Layout>
				<Layout.Section>
					<AutoRefresh
						pollingEnabled={pollingEnabled}
						togglePolling={togglePolling}
						discriminatorData={ordersData}
					/>
					<ErrorBanner
						isVisible={isErrorBannerVisible}
						onDismiss={() => {
							setIsErrorBannerVisible(false);
						}}
					/>
				</Layout.Section>
				<Layout.Section>
					<Card>
						<div style={{ paddingLeft: '1rem', paddingRight: '1rem', paddingTop: '1rem' }}>
							<ExceptionsFilters queryValue={searchText} onQueryChange={setSearchText} />
						</div>

						<PrintLabelsBanner
							isVisible={didPrintLabels}
							onDismiss={() => setDidPrintLabels(false)}
						/>

						<ExceptionsTable
							loading={loading}
							data={orders}
							onManuallyResolve={(selectedIds) => void handleManuallyResolve(selectedIds)}
							selectable={selectable}
							actions={actions}
						/>
						<Card.Section>
							<Stack distribution="center">
								<Pagination
									label={`${paginationCursors.length + 1} ${messages.of} ${Math.max(
										Math.ceil(totalCount / PAGE_SIZE),
										1,
									)}`}
									hasNext={ordersPageInfo?.hasNextPage}
									hasPrevious={ordersPageInfo?.hasPreviousPage}
									onNext={handleOnNextPage}
									onPrevious={handleOnPreviousPage}
								/>
							</Stack>
						</Card.Section>
					</Card>
				</Layout.Section>
				<Layout.Section>
					<TimezoneFooter />
				</Layout.Section>
			</Layout>
		</Page>
	);
}
