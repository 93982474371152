import { Stack, TextStyle } from '@sixriver/lighthouse-web-community';

import { SupportBadge } from './SupportBadge';
import { ltsVersion } from '../lts-dates';

export function LtsNumber() {
	// Note - untranslated as `LTS X` is not to be translated.  It's a version number.
	return (
		<Stack>
			<TextStyle>LTS {ltsVersion}</TextStyle>
			<SupportBadge />
		</Stack>
	);
}
