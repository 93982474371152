import { ShuttleRoute } from '@sixriver/task-coordinator-oas';

export type CycleType = ShuttleRoute['cycleType'];
export const CycleTypes: Record<string, CycleType> = {
	loop: 'loop',
	oneShot: 'oneShot',
	pingPong: 'pingPong',
};

export type CycleTypeValues = (typeof CycleTypes)[keyof typeof CycleTypes];
export const CycleTypeValuesArray: CycleTypeValues[] = Object.values(CycleTypes);

export interface ShuttleSelection {
	id: string;
	type: 'shuttle' | 'shuttlePoint';
}
