import { useState } from 'react';

export function useApplyManualPickHealPlan(): {
	fetching: boolean;
	applyManualHealPlan: (jobId: string) => Promise<void>;
} {
	const [fetching, setFetching] = useState(false);
	const applyManualHealPlan = async (jobId: string) => {
		try {
			setFetching(true);
			const response = await fetch('/cfs/mcqs-projections/v1/jobs/applyManualPickHealPlan', {
				body: JSON.stringify({ jobId }),
				credentials: 'include',
				headers: {
					'Content-Type': 'application/json',
				},
				method: 'POST',
			});

			if (!response.ok) {
				throw new Error(`Failed to apply MANUAL_PICK heal plan to ${jobId}`);
			}
		} catch (err) {
			if (err instanceof Error) {
				throw err;
			}

			throw new Error(`Failed to apply MANUAL_PICK heal plan to ${jobId}`);
		} finally {
			setFetching(false);
		}
	};

	return { applyManualHealPlan, fetching };
}
